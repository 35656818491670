import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About Us | About Dakolanites
			</title>
			<meta name={"description"} content={"Welcome to Dakolanites, where the game is not just on the screen – it's in every cheer, every game, and every shared moment. "} />
			<meta property={"og:title"} content={"About Us | About Dakolanites"} />
			<meta property={"og:description"} content={"Welcome to Dakolanites, where the game is not just on the screen – it's in every cheer, every game, and every shared moment. "} />
			<meta property={"og:image"} content={"https://dakolanites.com/img/banner.jpg"} />
			<link rel={"shortcut icon"} href={"https://dakolanites.com/img/image_processing.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://dakolanites.com/img/image_processing.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://dakolanites.com/img/image_processing.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://dakolanites.com/img/image_processing.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://dakolanites.com/img/image_processing.png"} />
			<meta name={"msapplication-TileImage"} content={"https://dakolanites.com/img/image_processing.png"} />
			<meta name={"msapplication-TileColor"} content={"https://dakolanites.com/img/image_processing.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="--color-light">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					About Us
				</Text>
				<Text
					font="--lead"
					color="--darkL2"
					text-align="left"
					md-width="100%"
					max-width="1000px"
				>
					Welcome to Dakolanites, where the game is not just on the screen – it's in every cheer, every game, and every shared moment. Step into a world where camaraderie flows as smoothly as our craft beers, and where victory is measured not just in points but in the friendships forged over pints and banter. Join us for an unforgettable experience, where legends are born over games and every visit is a celebration of the joy that comes from being part of the Dakolanites family.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://dakolanites.com/img/a1.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						Our Story
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						At Dakolanites, we believe in the magic that happens when sports enthusiasts gather under one roof. Our journey began with a simple idea – to create a space where the love for the game is as important as the thrill of competition, and where strangers become friends through shared cheers and playful banter.
						<br />
						<br />
						Established with the vision of providing a laid-back yet vibrant atmosphere, Dakolanites has evolved into more than just a sports pub. It's a haven for those seeking a respite from the daily grind, a place where camaraderie is celebrated as much as the victories on the games we love.
					</Text>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://dakolanites.com/img/a2.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
						What Sets Us Apart:
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
						• Passion on Tap: From the foamy pour of a perfectly crafted beer to the strategic flick of a shuffleboard puck, every detail is infused with our passion for creating memorable experiences.
						<br />
						<br />
						• Friendship Forged in Play: Beyond the buzz of live broadcasts, our space is alive with the sound of laughter from beer pong battles, and the friendly clatter of shuffleboard pucks.
						<br />
						<br />
						• No Strangers, Only Friends: Whether you're a solo spectator or part of a rowdy group, you'll find that at Dakolanites, there are no strangers, only friends you haven't met yet.
					</Text>
				</Box>
			</LinkBox>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0"
			min-height="300px"
			sm-min-height="auto"
			background="--color-light"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				font="--base"
				text-transform="uppercase"
				letter-spacing="1px"
				color="--grey"
				margin="0px 0px 8px 0px"
				width="100%"
			>
				The Dakolanites Experience
			</Text>
			<Text
				as="h2"
				margin="0px"
				font="--headline2"
				md-font="--headline2"
				color="--dark"
				width="40%"
				padding="0px 16px 0px 0px"
				md-width="100%"
				md-margin="0px 0px 32px 0px"
				md-padding="0px 0px 0px 0px"
			>
				Meet the Team
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="40%"
				padding="0px 16px 0px 16px"
				md-width="50%"
				md-padding="0px 16px 0px 0px"
				sm-width="100%"
				sm-margin="0px 0px 16px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				Behind the bar and throughout the pub, our team is committed to ensuring you have the time of your life. From recommending the perfect brew to organising impromptu games, our staff is here to make every visit memorable.
			</Text>
			<Button
				margin="0px"
				font="--base"
				color="--dark"
				width="20%"
				padding="0px 16px 0px 16px"
				md-width="50%"
				md-padding="0px 0px 0px 16px"
				sm-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 32px 0px"
				type="link"
				href="/contacts"
				text-align="center"
				align-self="auto"
				display="flex"
				justify-content="center"
				text-decoration-line="underline"
				align-items="center"
				border-radius="8px"
				background="rgba(0, 0, 0, 0)"
			>
				Visit Us
			</Button>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});